<template>
  <div>
    <modal :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(handleCmcAdjustmentUpdate)" autocomplete="off">
            <div>
              <!-- Central -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Central
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <el-select disabled v-model="adjustment.company_plant.name" size="mini">
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Ponto de carga -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Ponto de carga
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.charge_point.name" size="mini" disabled>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Categoria -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Categoria
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.cmc_category.name" disabled size="mini">
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Grupo -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Grupo
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.cmc_group.name" size="mini" disabled>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- MCC Produto - Fornecedor(Procedência)  -->
              <div class="form-group row m-0 p-0 pt-3">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  MCC
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="titleCmc" size="mini" disabled>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Locais de dosagem  -->
              <div class="form-group row m-0 p-0 pt-3">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Locais de dosagem
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.dosage_locations" multiple size="mini" filterable
                                 placeholder="Selecione">
                        <el-option v-for="dosage in dosageLocationItems" :key="dosage.id"
                                   :label="dosage.name +' ('+ dosage.dosage_location.name+' '+ dosage.code+')'"
                                   :value="dosage.uuid">
                        </el-option>
                        <el-option :value="5" label="Dosagem Manual (Central)"></el-option>
                        <el-option :value="6" label="Dosagem Manual (Obra)"></el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <!-- Unidade de dosagem  -->
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                  Unidade de dosagem
                </label>
                <div class="col-md-6 mb-1 pr-4">
                  <validation-provider>
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="adjustment.measured_unit_uuid" size="mini" placeholder="Selecione">
                        <el-option v-for="measured_unit_dosage in measuredUnitDosages" :key="measured_unit_dosage.id"
                                   :label="measured_unit_dosage.letter" :value="measured_unit_dosage.uuid">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-5 pb-0 mb-1 mt-2 col-form-label form-control-label">
                  REMOVER LOCAIS DE DOSAGEM IGUAIS EM OUTRO MCC?
                </label>
                <div class="col-md-6 mb-1 mt-md-3 ml-n2 pr-4">
                  <base-input class="ml-2">
                    <base-switch v-model="adjustment.remove_same_dosage_location" type="primary"/>
                  </base-input>
                </div>
              </div>
              <div class="modal-footer">
                <base-button type="secondary" @click="closeModal('create')">
                  Cancelar
                </base-button>
                <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore">
                  Salvar
                </base-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

import {
  formatErrorValidation
} from '@/plugins'

/** Histórico */
import { cmcAdjustmentCreateHistoryService } from "../../services";
import AdjustmentHistoryActionsEnum from "../../../adjustment-history/enums/AdjustmentHistoryActionsEnum";

export default {
  name: "EditCmcAdjustment",
  data() {
    return {
      modal: {
        title: 'Ajuste MCC',
        create: false,
      },
      charge_point_select: false,
      group_select: false,
      category_select: false,
      adjustment: {
        uuid: null,
        company_plant: {},
        cmc_group: {},
        charge_point: {},
        cmc_category: {},
        cmc: {},
        measured_unit_uuid: {},
        dosage_locations: [],
        remove_same_dosage_location: true,
      },
      titleCmc: null,
      loadingStore: false,
      selectedGroups: [],
      cmcItems: [],
      chargePointItems: [],
      dosageLocationItems: [],
      measuredUnitBkp: null,
      dosageLocationBkp: null
    }
  },
  computed: {
    ...mapGetters({
      'plants': 'plant/fetch',
      'chargePoints': 'plantChargePoint/fetch',
      'categories': 'category/fetch',
      'groups': 'cmcGroup/showGroupByCategory',
      'cmcs': 'cmc/fetch',
      'cmcAdjustments': 'adjustmentCmc/fetch',
      'measuredUnitDosages': 'measuredunit/measured_unit_dosage',
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false
    },
    addDosage() {
      let dosages = this.adjustment.dosages
      if (this.currentDosage.stabilization_time && this.currentDosage.dosage_content) {
        if (dosages && dosages[0] && dosages.filter(item => item.stabilization_time == this.currentDosage.stabilization_time)[0]) {
          this.$notify({
            type: 'danger',
            message: 'Tempo de estabilização já cadastrado!'
          })
        } else {
          let dosage = {}
          dosage.stabilization_time = this.currentDosage.stabilization_time
          dosage.dosage_content = this.currentDosage.dosage_content
          this.adjustment.dosages.push(dosage)
        }
      }
    },
    removeDosage(index) {
      this.adjustment.dosages.splice(index, 1);
    },
    getProduct(id) {
      if (this.adjustment.supplier_origin_id) {
        this.productselect = false
        this.adjustment.product_id = null
        let params = {
          supplierId: this.supplier_id,
          supplierOriginId: this.adjustment.supplier_origin_id,
        }
        this.$store.dispatch('cmc/fetchBySupplierIdAndSupplierOriginId', params).then(() => {
          this.productselect = true
        })
      } else {
        this.adjustment.product_id = null
      }
    },
    getDosageLocations() {
      if (this.adjustment.cmc_uuid) {
        this.dosageLocationItems = []
        let charge_point_id = this.chargePoints.filter(item => item.uuid == this.adjustment.charge_point_uuid)[0].id
        let params = {
          chargePointId: charge_point_id,
          categoryId: this.adjustment.cmc_category_uuid
        }
        this.$store.dispatch('plantChargePointLocation/fetchByChargePointAndCategory', params).then((response) => {
          this.dosageLocationItems = response.data
        })
      } else {
        this.dosageLocationItems = []
      }
    },
    handleEditModal(uuid) {
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      })
      this.$Progress.start()
      this.$store.dispatch('adjustmentCmc/show', uuid)
        .then((response) => {
          this.adjustment = {
            uuid: response.data.uuid || '',
            company_plant: response.data.company_plant || '',
            charge_point: response.data.charge_point,
            cmc_category: response.data.cmc_category || '',
            cmc_group: response.data.cmc_group || '',
            cmc: response.data.cmc || '',
            measured_unit_uuid: response.data.measured_unit.uuid || '',
            dosage_locations: response.data.dosage_locations || '',
            remove_same_dosage_location: true,
          }
          this.measuredUnitBkp = this.adjustment.measured_unit_uuid;
          this.titleCmc = this.adjustment.cmc.product + ' - ' + this.adjustment.cmc.supplier + '(' + this.adjustment.cmc.supplier_origin + ')';
          let dosage_locations = []
          this.adjustment.dosage_locations.forEach(function (obj, index) {
            if (obj.code != 0) {
              dosage_locations.push(obj.uuid)
            } else {
              dosage_locations.push(obj.id_dosage_location)
            }

          });
          this.adjustment.dosage_locations = dosage_locations;
          this.dosageLocationBkp = dosage_locations;
          this.$store.dispatch('plantChargePoint/getByPlant', this.adjustment.company_plant.uuid).then((response) => {
            let charge_point_id = this.chargePoints.filter(item => item.uuid == this.adjustment.charge_point.uuid)[0].id
            let category_id = this.categories.filter(item => item.uuid == this.adjustment.cmc_category.uuid)[0];
            category_id = category_id ? (category_id.uuid === "0" ? 99 : category_id.id) : undefined; // solução rápida para resolver problema ao filtrar por Puzl Admix
            let params = {
              chargePointId: charge_point_id,
              categoryId: category_id
            }
            this.$store.dispatch('plantChargePointLocation/fetchByChargePointAndCategory', params).then((response) => {
              this.dosageLocationItems = response.data
              this.modal.create = true
              this.$Progress.finish()
              this.$notify({
                type: response.error_type,
                message: response.message
              })
            })
          })

        }).catch((error) => {
        if (error.response.status === 422) {
          let errors = formatErrorValidation(error.response.data.errors)
          this.$notify({
            type: 'danger',
            message: errors
          })
        }
        this.$Progress.finish()
        this.loadingStore = false
      })
    },
    handleCmcAdjustmentUpdate() {
      this.$Progress.start()
      this.loadingStore = true
      this.adjustment.cmc_category_uuid = this.adjustment.cmc_category.uuid
      this.adjustment.charge_point_uuid = this.adjustment.charge_point.uuid
      if (this.adjustment.dosage_locations.includes(5) || this.adjustment.dosage_locations.includes(6)) {
        JSON.parse(JSON.stringify(this.adjustment.dosage_locations))
        this.adjustment.manual_dosages = this.checkManualDosage(this.adjustment.dosage_locations, this.adjustment.cmc_category_uuid)
      }
      this.$store.dispatch('adjustmentCmc/update', this.adjustment)
        .then(response => {
          let hasDifferenceDosageLocation = this.dosaleLocationHasDifference(
            this.dosageLocationBkp,
            this.adjustment.dosage_locations
          );

          if(hasDifferenceDosageLocation || this.measuredUnitBkp !== this.adjustment.measured_unit_uuid) {
              /**
             * Criando histórico de edição
             */
            const cmcAdjustment = {
              group_name: this.adjustment.cmc_group.name,
              cmc_product: this.adjustment.cmc.product,
              cmc_supplier: this.adjustment.cmc.supplier,
              supplier_origin: this.adjustment.cmc.supplier_origin,
              category_name: this.adjustment.cmc_category.name,
              dosage_locations: this.mountDosageLocationsToHistory(this.dosageLocationItems, this.adjustment.dosage_locations)
            };


            cmcAdjustmentCreateHistoryService.execute(
              AdjustmentHistoryActionsEnum.keys.EDIT,
              cmcAdjustment
            );
          }
          this.loadingStore = false
          this.modal.create = false
          this.$Progress.finish()
          this.$notify({
            type: response.error_type,
            message: response.message
          })
          this.$emit("updatedCmcAdjustment");
        })
        .catch(error => {
          if (error.status == 200) {
            this.$notify({
              type: 'danger',
              message: error.data.message
            })
            this.$Progress.finish();
            this.loadingStore = false
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors)
            this.$notify({
              type: 'danger',
              message: errors
            })
          }
          this.$Progress.finish()
          this.loadingStore = false
        })
    },
    checkManualDosage(dosages, category_uuid) {
      let manual_dosages = [];
      if (dosages.includes(5)) {
        let dosage = {
          dosage_location_id: 5,
          company_plant_charge_point_id: this.chargePoints.filter(item => item.uuid == this.adjustment.charge_point.uuid)[0].id,
          company_plant_id: this.plants.filter(item => item.uuid == this.adjustment.company_plant.uuid)[0].id,
          company_id: this.plants.filter(item => item.uuid == this.adjustment.company_plant.uuid)[0].company_id,
          cmc_category_id: this.categories.filter(item => item.uuid == this.adjustment.cmc_category.uuid)[0].id
        };
        manual_dosages.push(dosage)
      }
      if (dosages.includes(6)) {
        let dosage = {
          dosage_location_id: 6,
          company_plant_charge_point_id: this.chargePoints.filter(item => item.uuid == this.adjustment.charge_point.uuid)[0].id,
          company_plant_id: this.plants.filter(item => item.uuid == this.adjustment.company_plant.uuid)[0].id,
          company_id: this.plants.filter(item => item.uuid == this.adjustment.company_plant.uuid)[0].company_id,
          cmc_category_id: this.categories.filter(item => item.uuid == this.adjustment.cmc_category.uuid)[0].id
        };
        manual_dosages.push(dosage)
      }
      return manual_dosages
    },
    /**
     * 
     * @param {Array} oldDosageLocation 
     * @param {Array} newDosageLocation 
     * @returns {boolean}
     */
    dosaleLocationHasDifference(oldDosageLocation, newDosageLocation) {
      if (oldDosageLocation.length !== newDosageLocation.length) return true;

      for (let i = 0; i < oldDosageLocation.length; i++) {
          if (oldDosageLocation[i] !== newDosageLocation[i]) return true;
      }

      return false;
    },
    /**
     * @todo método temporário para criação de histórico
     * de ajuste de locais de dosagem. Esta modal, assim
     * como a de criação e o modal de referência serão
     * refatorados para o novo padrão
     * 
     * @param {array} dosageLocationItems 
     * @param {array} dosage_locations 
     * @returns {array}
     */
    mountDosageLocationsToHistory(dosageLocationItems, dosage_locations) {

      let dosageLocationHistory = [];

      dosage_locations.forEach(uuid => {
        dosageLocationItems.forEach(item => {
          if(uuid === item.uuid) {
            dosageLocationHistory.push({ name: `${item.name} (${item.dosage_location.name}) ${item.code}` });
          }
        });
      });

      if(this.adjustment.manual_dosages) {
        this.adjustment.manual_dosages.forEach(manual => {
          if(manual.dosage_location_id === 5) {
            dosageLocationHistory.push({ name: "dosagem manual (central)" });
          } else if(manual.dosage_location_id === 6) {
            dosageLocationHistory.push({ name: "dosagem manual (obra)" });
          }
        });
      }

      this.measuredUnitDosages.forEach(unit => {
        if(this.adjustment.measured_unit_uuid === unit.uuid) {
          dosageLocationHistory.push(unit.letter);
        }
      });

      return dosageLocationHistory;
    }
  },
  mounted() {
    this.$store.dispatch('measuredunit/fetchItems')
  }
}
</script>

<style scoped>

</style>
