<template>
  <div class="bg-white p-2 rounded-sm">
    <div class="row">
      <ShadedTable :length="listed.items.length" :headers="headers" :loading="isListing">
        <template v-for="(item, indexItem) in listed.items" :slot="indexItem">
          <ShadedCol style="width: 15%;height: fit-content;">
            <span
              v-if="item.action === AdjustmentHistoryActionsEnum.keys.ADJUST"
              class="badge-table badge-table-action-adjust d-flex"
              style="gap: 5px;"
            >
              <b>Ajuste</b>
            </span>
            <span
              v-else-if="item.action === AdjustmentHistoryActionsEnum.keys.REMOVE"
              class="badge-table badge-table-action-remove d-flex"
              style="gap: 5px;"
            >
              <b>Remoção</b>
            </span>
            <span
              v-else-if="item.action === AdjustmentHistoryActionsEnum.keys.EDIT"
              class="badge-table badge-table-action-edit d-flex"
              style="gap: 5px;"
            >
              <b>Edição</b>
            </span>
          </ShadedCol>
          <ShadedCol style="width: 12%;height: fit-content;">
            <span class="table-content-font">
              {{
                item.type === AdjustmentHistoryTypesEnum.keys.INDIVIDUAL ? AdjustmentHistoryTypesEnum.getTitle(AdjustmentHistoryTypesEnum.keys.INDIVIDUAL)
                : AdjustmentHistoryTypesEnum.getTitle(AdjustmentHistoryTypesEnum.keys.GENERAL)
              }}
            </span>
          </ShadedCol>
          <ShadedCol style="width: 12%;height: fit-content;">
            <div class="d-flex">
              <span class="table-content-font">
                {{ date.make(item.created_at).format(date.FORMAT.SHORT_DATE_AND_TIME) }}
              </span>
            </div>
            <div class="ml-auto">
              <div class="mx-1">
                <el-popover
                  class="ml-0"
                  placement="bottom"
                  trigger="click"
                >
                  <div class="mb-n2">
                    <span>
                      <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                        USUÁRIO
                      </span>
                      <hr class="hr-popover-user">
                      <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ item.created_by_user_name }} </span>
                    </span>
                  </div>
                  <base-button
                    slot="reference"
                    class="p-0 m-0 shadow-none"
                    outiline
                    size="sm"
                    type="secundary"
                  >
                    <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                  </base-button>
                </el-popover>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol style="width: 12%;height: fit-content;">
            <div class="d-flex">
              <span class="table-content-font">
                {{ AdjustmentEnum.getTitle(item.adjust) }}
              </span>
            </div>
            <div
              v-if="item.type === AdjustmentHistoryTypesEnum.keys.INDIVIDUAL && item.action === AdjustmentHistoryActionsEnum.keys.ADJUST"
              class="ml-auto"
            >
              <div class="mx-1">
                <el-popover
                  class="ml-0"
                  placement="bottom"
                  trigger="click"
                >
                  <div class="mb-n2">
                    <span>
                      <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                        CENTRAL
                      </span>
                      <hr class="hr-popover-user">
                      <div v-for="(company_plant, indexCompanyPlant) in item.company_plants">
                        <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ company_plant }} </span>
                      </div>
                    </span>
                  </div>
                  <base-button
                    slot="reference"
                    class="p-0 m-0 shadow-none"
                    outiline
                    size="sm"
                    type="secundary"
                  >
                    <img src="/img/icons/icons8/ios/chemical-plant-v1.png"
                      alt="chemical-plant-v1"
                      width="22px"
                      @click="onCompanyPlantsByIdClick(item)"
                    >
                  </base-button>
                </el-popover>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol style="width: 50%;height: fit-content;">
            <div class="d-flex">
              <span class="text-left table-content-font">
                {{ item.title }}
              </span>
              <div
                v-if="
                  item.action === AdjustmentHistoryActionsEnum.keys.ADJUST
                  &&
                  item.adjust === AdjustmentEnum.keys.MCC
                "
                class="ml-auto"
              >
                <TechnicalAdjustments
                  :adjustment="item"
                />
              </div>
              <div
                v-else-if="
                  item.action === AdjustmentHistoryActionsEnum.keys.ADJUST
                  &&
                  item.adjust === AdjustmentEnum.keys.MCC_CONTENT
                "
                class="ml-auto"
              >
                <ContentAdjustments
                  :adjustment="item"
                />
              </div>
              <div
                v-else-if="
                  item.action === AdjustmentHistoryActionsEnum.keys.EDIT
                  &&
                  item.adjust === AdjustmentEnum.keys.MCC
                "
                class="ml-auto"
              >
                <DosageLocationAdjustments
                  :adjustment="item"
                />
              </div>
            </div>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import adjustmentHistoryStore from "../../store";
import { AdjustmentHistoryListType } from "../../types";
import AdjustmentEnum from "../../enums/AdjustmentEnum";
import AdjustmentHistoryActionsEnum from "../../enums/AdjustmentHistoryActionsEnum";
import AdjustmentHistoryTypesEnum from "../../enums/AdjustmentHistoryTypesEnum";
import ShadedTable from "../../../../../components/ShadedTable.vue";
import ShadedCol from "../../../../../components/ShadedCol.vue";
import { date } from "../../../../../helpers";
import TechnicalAdjustments from "../shared/popovers/TechnicalAdjustments.vue";
import ContentAdjustments from "../shared/popovers/ContentAdjustments.vue";
import DosageLocationAdjustments from "../shared/popovers/DosageLocationAdjustments.vue";

const headers = [
  "Ação",
  "Tipo",
  "Data",
  "Ajuste",
  "Referência"
];

/** Eventos emitidos */
const emit = defineEmits([
  "onCompanyPlantsByIdClick",
]);

/** Store Getters */
const listed = computed(() => adjustmentHistoryStore.getListed());
const isListing = computed(() => adjustmentHistoryStore.getIsListing());

/**
 * 
 * @param {AdjustmentHistoryListType} history 
 */
function onCompanyPlantsByIdClick(history) {
  // se já buscou central daquele histórico
  // não precisa buscar novamente
  if(history.company_plants === null) {
    emit("onCompanyPlantsByIdClick", history);
  }
}

</script>

<style scoped>
.badge-table {
  padding: 6px 19px;
  border-radius: 8px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.badge-table b {
  font-weight: 400;
  font-size: 12px;
}
.badge-table-action-adjust {
  background-color: #F2F4F9;
  color: #1A70B7;
}
.badge-table-action-remove {
  background-color: #FCF3F3;
  color: #DB4539;
}
.badge-table-action-edit {
  background-color: #FEF9F2;
  color: #F2B532;
}
.table-content-font {
  font-size: 14px;
  font-weight: 400;
  color: #2B2D32;
}
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
}
.hr-popover-user {
  margin: 7px 0;
  width: 118%;
  margin-left: -11px;
}
</style>