export * as Object from '../helpers/object'
export * as Array from '../helpers/array'
export {verifyDocument} from "./document";
export { default as date } from "./date";
export { default as dialogs } from "./dialogs";
export { default as progress } from "./progress";
import moment from 'moment'
import moment_tz from 'moment-timezone'
export {viacep}  from './addresses'
export const isNumber = function (n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function roundWithDecimal(num, decimal) {
    const pow = Math.pow(10, decimal);
    return Math.ceil(num * pow) / pow;
}
export function round(number, precision, withFormat = false) {
  const factor = Math.pow(10, precision);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  let result = roundedTempNumber / factor;
  if (withFormat) {
    return result.toString().replace('.', ',')
  }
  return Number(result).toFixed(2)
}
export function copy(code)
{
  const el = document.createElement('textarea');
  el.value = code;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

/**
 * Converte a data formatada na função minutesToTime em minutos
 * Exemplo: 1h 30min são 90 minutos
 * @param date
 * @returns {number}
 */
export function dateToMinutes(date) {
  let minutes = 0
  const separeted = date.split(' ')
  for (var k of separeted) {
    const preg = k.replace(/[^0-9]/g, '')
    if (k.includes('h')) {
      minutes += Math.round(preg * 60)
    } else {
      minutes += Math.round(preg)
    }
  }
  return minutes
}
export function minutesToTime(minutes, explicit = false) {
  if (minutes < 1 || !minutes) return "00:00"
  let day = 'd'
  let hour = 'h'
  if (explicit) {
    day = ' dia'
    hour = ' hr'
  }
  const diffs = [
    Math.floor(minutes / 1440)+day,
    Math.floor((minutes % 1440) / 60)+hour,
    Math.floor((minutes % 1440) % 60)+'min'
  ]
  return diffs.map((item) => {
    const unft = item.replace(/[a-zA-Z]{1,3}/, '')
    return unft > 0 ? `${item} ` : ''
  }).filter((a) => a).join('')
}
export function secondsToTime(seconds, explicit = false) {
  if (seconds < 60 || !seconds) return "00:00"
  let day = 'd'
  let hour = 'h'
  if (explicit) {
    day = ' dia'
    hour = ' hr'
  }
  const diffs = [
    Math.floor(seconds/86400)+day,
    Math.floor(((seconds/86400)%1)*24)+hour,
    Math.floor((seconds % 3600) / 60)+'min'
  ]
  return diffs.map((item) => {
    const unft = item.replace(/[a-zA-Z]{1,3}/, '')
    return unft > 0 ? `${item} ` : ''
  }).filter((a) => a).join('')
}
// envie apenas data formatada, nao aceita o moment ou Date
export function dateDiff(first, second, formatted = true) {
  first = moment(first).format('YYYY-MM-DD H:mm')
  second = moment(second).format('YYYY-MM-DD H:mm')
  let seconds = moment.duration(moment(second).diff(moment(first))).asSeconds();
  return formatted ? secondsToTime(seconds) : seconds;
}

// Método retornará o valor caso seja um valor númerico ou um traço (-)
export const checkNumberValue = (value, min = "", max = "") => {
  if(!value){
    return ''
  }
  const isBigger = (n) => max && parseFloat(n) >= parseFloat(max) ? true : false
  const isSmaller = (n) => min && parseFloat(n) <= parseFloat(min) ? true : false
  if (isNumber(value.substr(-1))) {
    return max && isBigger(value) ? max : min && isSmaller(value) ? min : value
  } else if (value.length === 1 && value.substr(-1) === '-') {
    return value
  }
  return value.slice(0, -1)
}

// Insere um caractere se atender a condição
// Caso o Char não for definido retorna |
export const insertCharNext = (hasNext, char = "|") => hasNext && char
export const parseDate = (date, formatter = 'DD MMM YYYY H:mm') => {
  return moment_tz(date).tz('America/Sao_Paulo').locale("pt-br").format(formatter)
}

export const calcPercentage = function (percentage, value) {
  return Number(value) * (Number(percentage) / 100)
}

export const optionalChaining = function (obj = {}, properties = ""){

  const levels = properties.split(".");
  let objProperty = Object.assign({}, obj);

  for ( let level of levels){
    objProperty =  objProperty[level];
    if(!objProperty)
      return null;
  }
  return objProperty;
}

export function toMoney(value) {
  value = Number(value)
  value = parseFloat(value)
  value = value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
  return value
}
export function toMoneyWithoutCurrencyLayout(value) {
  value = Number(value)
  value = value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2})
  return value
}

export function toDecimal(value, decimalPlaces = 2, mustFloat = false) {
  value = Number(value)
  value = parseFloat(value)
  value = value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: decimalPlaces});
  return mustFloat ? parseFloat(value.replace(',','.')) : value;
}

/**
 * @description
 * Colocar zeros/outro caracter informado a esquerda
 *
 * @param {string} value
 * @param {integer} totalLength
 * @param {string} paddingChar
 * @returns {string}
 */
 export function leftPad(value, totalLength, paddingChar) {
  const valueLength = value.toString().length;
  if (totalLength < valueLength) totalLength = valueLength
  const setLength = totalLength - valueLength + 1;
  return Array(setLength).join(paddingChar || '0') + value;
};


// Descobrir percentual entre dois números
export function percentageFrom(baseValue, finalValue, dontAllowZero = false) {
  if(dontAllowZero && (!parseFloat(baseValue) || !parseFloat(finalValue))) return 0
  return ((Number(finalValue) / Number(baseValue)) * 100).toFixed(2);
};

/**
 * @description
 * Recebe um valor numérico do tipo String e converte para tipo Number.
 *
 * @param str Valor Numérico com tipagem em String.
 * @param def Valor Default quando parâmetro "str" for inválido.
 *
 * @returns Number
 */
export function strToNum (str, def = 0, keepNegativeSign = false) {
  // Transformar campo null em 0
  if (!str) {
    str = 0;
  }

  // Manter o sinal negativo se houver
  const isNegative = (typeof str === 'string' && str.trim().charAt(0) === '-') || (typeof str === 'number' && str < 0);

  // Retorna se o tipo for number
  if (typeof str === 'number') {
    return isNegative && keepNegativeSign ? str : Math.abs(str);
  }

  // Remover sinal negativo temporariamente para processamento
  str = str.replace('-', '');

  // Tratar valor >= milhão Padrão BR
  if (str.split('.').length > 2) {
    return isNegative && keepNegativeSign
      ? -parseFloat(str.replaceAll('.', '').replaceAll(',', '.'))
      : parseFloat(str.replaceAll('.', '').replaceAll(',', '.'));
  }

  // Tratar valor >= milhão Padrão EUA
  if (str.split(',').length > 2) {
    return isNegative && keepNegativeSign
      ? -parseFloat(str.replaceAll(',', ''))
      : parseFloat(str.replaceAll(',', ''));
  }

  // Tratar apenas quando houver uma única casa decimal
  if ((str.toString().charAt(str.length - 2) === '.') || (str.toString().charAt(str.length - 2) === ',')) {
    str += '0';
  }

  // Definir casas decimais de expressão regular
  const decimalPlaces = (str.split(',').length > 1) ? str.split(',')[1].length : 2;
  const exp = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/;
  const cur_re = new RegExp(exp.source.replaceAll('2', decimalPlaces));

  // Executar expressão regular
  let parts = cur_re.exec(str);

  // Retorna o resultado
  const result = (parts === null)
    ? def
    : parseFloat(parts[1].replace(/\D/, '') + '.' + (parts[2] ? parts[2] : '00'));

  return isNegative && keepNegativeSign ? -result : result;
};

/**
 * Remove todos os caracteres não numéricos de uma string.
 *
 * @param {string} str - A string a ser processada.
 * @returns {string} Uma nova string contendo apenas os caracteres numéricos da string original.
 */
export function onlyNumbers (str) {
  return str.replace(/\D/g, "");
}

export function moneyToFloat(str) {
  return parseFloat(str.replaceAll(".", "").replace(",", "."));
}

export function floatToHuman(num) {
  if (! num) {
    num = '0.0'
  }
  return num.toString().replace('.', ',')
}

/**
 * @description
 * Recebe um array e uma função para agrupar.
 * Retorna um array agrupado.
 *
 * @param list Lista de array.
 * @param keyGetter Função para agrupar. Exemplo:
 *                  function (item) { return item.nome } ou item => item.nome
 *
 * @returns Array agrupado com base em KeyGetter.
 */
export function groupArrayBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return Array.from(map.values());
}

// retorna propiedade x de um array de objetos
export function getPropertiesByArray(property, array) {
  return array.reduce(function (a,o) {
    return (a.push(o[property]), a)
  }, [])
}

/**
 * Clonar objeto sem referência de ponteiro
 * @param {object} object
 * @returns object
 */
export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object))
}

/**
 * Verifica se propriedade global $user está definida, se não estiver busca do storage
 *
 * @param {object} component
 */
export function keepGlobalUser(component)
{
  if(!component.$user || (component.$user && Number.isInteger(component.$user))) {
    component.$user = JSON.parse(localStorage.getItem('puzl_user'));
  }
}

/**
 * Retorna nome do cliente. ex:superbeton
 */
export function clientName() {
  const host = window.location.host;
  return host.split('.')[0];
}

/**
 * Limita uma string se ela exceder o comprimento máximo especificado.
 * @param {string} str
 * @param {number} maxLength
 */
export function truncateString(str, maxLength) {
  if (str && str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}

/**
 * Formata filter.global para custom_search. Utilizado para pesquisa customizada
 *
 * @param {string[]} columns - Um array de strings contendo os nomes das colunas do banco.
 * @param {string[]} filterGlobal - Um array de strings contendo os valores à pesquisar.
 * @returns {object|null} Um objeto contendo os arrays de colunas e valores, ou null se filterGlobal for falso.
 */
export function customSearchFormatter(columns, filterGlobal) {
  if (!filterGlobal){
    return null;
  }
  return {
    columns: columns,
    values: [...filterGlobal]
  };
}

/**
 * Cria uma função que só é executada após um período de espera,
 * útil para limitar a frequência de execução de uma função em resposta a eventos rápidos.
 *
 * @param {Function} func - A função a ser chamada após o período de espera.
 * @param {number} defaultWait - Tempo padrão em milissegundos a aguardar antes de executar a função.
 * @returns {Function} - Função que aguarda o período de espera e reinicia o temporizador se chamada novamente durante esse tempo.
 */
export function debounce(func, defaultWait) {
  let timeout;
  return function(wait, ...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait || defaultWait);
  };
};

/**
 * Remove as chaves especificadas de um objeto.
 *
 * @param {Object} obj - O objeto do qual as chaves serão removidas.
 * @param {Array} excludeKeys - Chaves a serem removidas.
 * @returns {Object} - O objeto sem as chaves especificadas.
 */
export function removeKeys(obj, excludeKeys) {
  const newObj = JSON.parse(JSON.stringify(obj));
  excludeKeys.forEach(key => delete newObj[key]);
  return newObj;
};

/**
 * Compara dois objetos com JSON.stringify para verificar se são iguais.
 *
 * @param {Object} obj1 - Primeiro objeto.
 * @param {Object} obj2 - Segundo objeto.
 * @param {Array} excludeKeys - Excluir chaves de comparação.
 * @returns {boolean} - `true` se os objetos forem iguais, caso contrário, `false`.
 */
export function areEqualObjects(obj1, obj2, excludeKeys = []) {
  // Verifica se ambos os valores são do tipo 'object' e não são null
  const isObject1 = obj1 !== null && typeof obj1 === 'object';
  const isObject2 = obj2 !== null && typeof obj2 === 'object';

  // Se qualquer um dos valores não for um objeto, retorna false
  if (!isObject1 || !isObject2) {
    return false;
  }

  // Remove as chaves especificadas dos objetos
  const filteredObj1 = removeKeys(obj1, excludeKeys);
  const filteredObj2 = removeKeys(obj2, excludeKeys);

  // Comparar objetos
  return JSON.stringify(filteredObj1) === JSON.stringify(filteredObj2);
}

/**
 * Converte uma URL de download em um objeto File.
 *
 * @param {string} url - A URL de download do arquivo.
 * @param {?string} extension - A extensão ou o nome completo desejado para o arquivo (opcional).
 *                              Pode ser uma extensão como 'png' ou o nome completo com a extensão como 'imagem.png'.
 * @returns {Promise<File>} - Um objeto File.
 */
export async function urlToFile(url, extension = null) {
  // Faz o download
  const response = await fetch(url);
  const blob = await response.blob();

  // Extrai o nome do arquivo da URL
  const urlObj = new URL(url);
  const pathParts = urlObj.pathname.split('/');
  let fileName = pathParts[pathParts.length - 1];

  // Mapeamento de extensões para tipos MIME
  const mimeTypes = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    bmp: 'image/bmp',
    gif: 'image/gif',
    pdf: 'application/pdf',
    txt: 'text/plain',
    // Adicione outros tipos conforme necessário
  };

  // Ajusta o nome do arquivo e o tipo se a extensão for informada
  let mimeType = blob.type;
  if (extension) {
    const ext = extension.split('.').pop();
    const baseName = fileName.split('.').slice(0, -1).join('.') || fileName;
    fileName = `${baseName}.${ext}`;
    mimeType = mimeTypes[ext] || mimeType;
  }
  const adjustedBlob = new Blob([blob], { type: mimeType });

  // Retorna objeto File
  return new File([adjustedBlob], fileName, { type: mimeType });
}

/**
 * Converte um arquivo em uma string Base64.
 *
 * @param {File|object} file - O arquivo a ser convertido. Se for um objeto diferente de File, deve ter a propriedade `url`.
 * @param {boolean} [withDataUriScheme=false] - Indica se o resultado deve incluir o prefixo Data URI Scheme.
 * @returns {Promise<string>} Promessa string Base64 do arquivo.
 */
export async function fileToBase64(file, withDataUriScheme = false) {
  if (file && typeof file === 'object' && file.url) {
    file = await urlToFile(file.url);
  }
  if (!(file instanceof File)) {
    throw new Error('O parâmetro deve ser um objeto com a propriedade `url` ou um objeto File.');
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      if (withDataUriScheme) {
        resolve(base64String);
      } else {
        // Remove o prefixo Data URI Scheme, se houver
        const base64Data = base64String.replace(/^data:[a-zA-Z0-9+.-]+\/[a-zA-Z0-9+.-]+;base64,/, '');
        resolve(base64Data);
      }
    };
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });
};

/**
 * Obtém a extensão do arquivo a partir do tipo MIME.
 *
 * @param {File} file - O arquivo do qual obter a extensão.
 * @returns {string} A extensão do arquivo, como 'png', 'bmp', 'pdf', etc.
 */
export function getFileExtension(file) {
  const mimeType = file.type;
  if (mimeType) {
    const extension = mimeType.split('/')[1];
    if (extension) {
      return extension.toLowerCase();
    }
  }
  return 'unknown';
}

/**
 * Aguarda por um tempo especificado em milissegundos.
 *
 * @param {number} ms - Tempo de espera em milissegundos.
 * @returns {Promise<void>} Promessa que é resolvida após o tempo especificado.
 */
export function waitFor(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Verifica se o documento é um CNPJ.
 *
 * @param {string|null|undefined} doc - Documento a ser verificado.
 * @returns {boolean} - Retorna `true` se for CNPJ (mais de 11 dígitos), senão `false`.
 */
export function isCnpjDocument(doc) {
  return onlyNumbers(doc ?? '').length > 11;
};

/**
 * Gera uma lista de objetos com base nas chaves e títulos de um enum.
 *
 * @param {Object} enumObject - Objeto enum que contém as propriedades `keys` e `getTitle`.
 * @param {Object} enumObject.keys - Objeto contendo as chaves do enum, com os valores correspondentes.
 * @param {Function} enumObject.getTitle - Função que recebe um valor e retorna o título correspondente.
 * 
 * @returns {Array<{id: number|string, name: string}>} - Retorna uma lista de objetos
 */
export const getTitles = (enumObject) => {
  return Object.values(enumObject.keys).map((value) => ({
    id: value,
    name: enumObject.getTitle(value)
  }));
};
