const keys = Object.freeze({
  MCC: 1,
  MCC_CONTENT: 2,
});

/**
 * 
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.MCC:
      return "MCC";
    case keys.MCC_CONTENT:
      return "Teor de MCC";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle
};
